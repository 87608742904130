@import "/styles/mixins.scss";

.link {
  color: inherit;
  text-decoration: none;
}

.root {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 16px;

  &_mobile_carousel {
    margin-left: -24px !important;
    padding-right: 40px;
  }

  @include respond-mobile {
    margin: 0;
  }

  &_horizontal {
    flex-direction: row;
    margin: 0;
    gap: 16px;
  }

  &__content {
    display: flex;
    gap: 16px;

    &_horizontal {
      flex: 1;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 192px;

    img {
      object-fit: cover;
      border-radius: 12px;
    }

    &_horizontal {
      width: 328px;
    }

    &_mobile_wrapper {
      width: calc(100% - 40px);
      height: 196px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2em;
    color: #333333;
    margin-top: 8px;
    margin-bottom: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 48px;

    &_horizontal {
      margin: 0;
      min-height: unset;
      -webkit-line-clamp: 1;
    }
  }

  &__author {
    display: flex;
    gap: 8px;
    font-size: 14px;
    line-height: 1.43em;
    color: #2f80ed;
    align-items: center;
    margin-bottom: 8px;
    min-height: 24px;
  }

  &__rating {
    display: flex;
    gap: 8px;
    align-items: center;
    color: #ffc107;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5em;
    margin-bottom: 8px;

    &_horizontal {
      margin-bottom: 0;
    }

    &__stars {
      display: flex;
      gap: 4px;
    }
  }

  &__info {
    font-size: 14px;
    line-height: 1.43em;
    color: #828282;
    margin-bottom: 4px;

    &_horizontal {
      margin-top: 4px;
      margin-bottom: 8px;
    }
  }

  &__desc {
    font-size: 16px;
    line-height: 1.5em;
    color: #828282;
    margin-bottom: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 72px;
  }

  &__price {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.33em;
    color: #27ae60;

    &_horizontal {
      min-width: 100px;
      text-align: right;
    }

    &_free {
      color: #333333;
    }
  }

  &__favourite {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;

    svg {
      color: #bdbdbd;
    }
  }

  &__lock {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
