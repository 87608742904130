.custom_rating {
  display: flex;
  gap: 8px;
  align-items: center;
  color: #ffc107;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5em;
  margin-bottom: 8px;

  &_horizontal {
    margin-bottom: 0;
  }

  &__reviews {
    color: #ffffff;
    font-weight: 400;
  }
}
