@import "/styles/mixins.scss";

.root {
  h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 1.25em;
    color: #333333;
    margin-bottom: 32px;

    @include respond-mobile {
      font-size: 24px;
      line-height: 1.33em;
    }
  }
}

.slider {
  > div {
    margin: 0 -16px;

    @include respond-mobile {
      margin: 0;
    }
  }

  &__arrow {
    position: absolute;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 1px solid #27ae60;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    @include respond-mobile {
      display: none;
    }

    &_next {
      right: -96px;
    }

    &_prev {
      left: -96px;
    }

    svg {
      color: #27ae60;
    }
  }
}
