@import "/styles/mixins.scss";

.root {
  display: flex;
  flex-direction: column;
  gap: 32px;
  scroll-margin-top: 108px;

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;

    &__title {
      font-weight: 700;
      font-size: 32px;
      line-height: 1.25em;
    }

    &__control {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-size: 18px;
        line-height: 1.78em;
      }
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 32px;

    &__filter {
      width: 328px;
      border-radius: 12px;
      border: 1px solid #e5e5e5;
      display: flex;
      flex-direction: column;
      position: sticky;
      top: 96px;

      @include respond-mobile {
        display: none;
      }

      &__heading {
        padding: 24px;
        border-bottom: 1px solid #e5e5e5;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #27ae60;
      }

      > div {
        border-bottom: 1px solid #e5e5e5;

        &:last-child {
          border-bottom: none;
          padding: 24px;
        }
      }
    }

    &__spacer {
      display: flex;
      justify-content: center;
      flex-grow: 1;
    }

    &__list {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  }
}
