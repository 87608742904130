@import "./mixins.scss";

.first_section {
  background-image: url("../assets/images/shape.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100% 500px;

  @include respond-mobile {
    background-size: 100% 100px;
  }

  &__content {
    max-width: 1408px;
    margin: 0 auto;

    @include respond-mobile {
      max-width: unset;
      margin: 0;
    }

    &__start {
      padding: 128px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @include respond-mobile {
        padding: 64px 20px;
      }

      h1 {
        font-weight: 800;
        font-size: 88px;
        color: #333333;
        margin-bottom: 16px;
        max-width: 1168px;

        @include respond-mobile {
          font-size: 32px;
          max-width: unset;
        }
      }

      p {
        font-size: 18px;
        line-height: 1.33em;
        color: #828282;
        margin-bottom: 32px;

        @include respond-mobile {
          font-size: 16px;
          line-height: 1.5em;
          max-width: unset;
        }
      }

      &__btn {
        color: #ffffff;
        padding: 20px 40px;
        font-weight: 700;
        font-size: 18px;
        line-height: 133%;
        letter-spacing: 0.05em;
        display: flex;
        align-items: center;
        gap: 16px;
        border-radius: 12px;

        @include respond-mobile {
          padding: 12px 24px;
          font-size: 16px;
          line-height: 1.25em;
        }
      }
    }

    &__demo {
      border-width: 5px 5px 0px 5px;
      border-style: solid;
      border-color: #27ae60;
      border-radius: 20px 20px 0px 0px;
      background-color: white;
      overflow-y: auto;
      max-height: 820px;

      @include respond-mobile {
        max-width: calc(100% - 40px);
        max-height: 190px;
        margin: 0 auto;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;

      img {
        border-radius: 20px 20px 0px 0px;

        @include respond-mobile {
          border-radius: 4px 4px 0px 0px;
        }
      }
    }
  }
}

.stats {
  margin: 0 auto;
  max-width: 1408px;
  padding: 128px 0;
  color: #333333;

  display: flex;
  flex-direction: column;

  @include respond-mobile {
    margin: 0;
    max-width: unset;
    padding: 56px 20px 32px 20px;
  }

  &__numbers {
    display: flex;
    gap: 16px;
    font-feature-settings: "liga" off;

    @include respond-mobile {
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }

    div {
      &:first-child {
        padding-left: 0;
        border-left: none;
      }

      &:last-child {
        @include respond-mobile {
          border-bottom: none;
        }
      }
    }

    &__item {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-left: 16px;
      border-left: 1px solid #e5e5e5;

      @include respond-mobile {
        border-left: none;
        padding-left: 0;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 24px;
      }

      h3 {
        font-weight: 800;
        font-size: 64px;
        margin-bottom: 12px;

        @include respond-mobile {
          font-size: 48px;
        }
      }

      p {
        font-weight: 300;
        font-size: 24px;
        line-height: 1.33em;

        @include respond-mobile {
          font-size: 20px;
        }
      }
    }
  }

  &__advantages {
    margin-top: 128px;

    @include respond-mobile {
      margin-top: 0;
    }

    &__heading {
      font-weight: 700;
      font-size: 48px;
      line-height: 1.17em;

      @include respond-mobile {
        font-size: 24px;
        line-height: 1.33em;
      }
    }

    &__row {
      margin-top: 48px;
      display: flex;
      gap: 32px;

      @include respond-mobile {
        flex-direction: column;
        margin-top: 32px;
        gap: 24px;
      }
    }

    &__col {
      flex: 1;
      display: flex;
      flex-direction: column;

      div {
        width: 100%;
        height: 252px;
        background: #ffffff;
        border: 1px solid rgba(229, 229, 229, 0.5);
        box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
        border-radius: 12px;
        margin-bottom: 32px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include respond-mobile {
          border: none;
          box-shadow: none;
          height: auto;
          margin-bottom: 16px;
        }
      }

      h5 {
        font-weight: 600;
        font-size: 24px;
        line-height: 1.33em;
        margin-top: 8px;

        @include respond-mobile {
          font-size: 20px;
          line-height: 1.6em;
          margin-top: 4px;
        }
      }

      p {
        font-size: 18px;
        line-height: 1.77em;

        @include respond-mobile {
          font-size: 14px;
          line-height: 1.78em;
        }
      }
    }
  }

  &__reviews {
    margin-top: 64px;

    @include respond-mobile {
      margin-top: 32px;
    }

    &__heading {
      font-weight: 700;
      font-size: 48px;
      line-height: 1.17em;

      @include respond-mobile {
        font-size: 24px;
        line-height: 1.33em;
      }
    }

    &__row {
      display: flex;
      gap: 32px;
      margin-top: 48px;

      @include respond-mobile {
        flex-direction: column;
        margin-top: 24px;
        gap: 48px;
      }
    }

    &__col {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__card {
      width: 100%;
      padding: 40px 52px;
      background: #ffffff;
      box-shadow: 0px 30px 40px rgba(212, 217, 232, 0.2);
      margin-bottom: 28px;
      height: 250px;
      position: relative;

      @include respond-mobile {
        padding: 24px 8px 8px 8px;
        height: auto;
      }

      h5 {
        font-weight: 600;
        font-size: 24px;
        line-height: 1.33em;
        margin-bottom: 8px;

        @include respond-mobile {
          font-size: 18px;
        }
      }

      p {
        font-size: 14px;
        line-height: 1.71em;
      }

      &::after {
        position: absolute;
        content: "";
        top: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-top: solid 15px #ffffff;
        border-left: solid 15px transparent;
        border-right: solid 15px transparent;
      }
    }

    &__student {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__name {
        font-weight: 700;
        font-size: 18px;
        line-height: 1.56em;
        margin-top: 8px;
      }

      &__position {
        font-size: 14px;
        line-height: 1.71em;
        color: #474a57;
      }
    }
  }
}

.quote {
  height: 334px;
  background-image: url("../assets/images/quote-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;

  @include respond-mobile {
    height: 146px;
    background-image: url("../assets/images/quote-bg-mobile.png");
    background-position: bottom center;
  }

  &__layer1 {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #27ae60;
    mix-blend-mode: color;
  }

  &__layer2 {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(270deg, #27ae60 0%, rgba(39, 174, 96, 0) 100%);
  }

  &__content {
    padding: 80px 0;
    max-width: 1408px;
    margin: 0 auto;
    color: #ffffff;
    z-index: 100;
    flex: 1;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @include respond-mobile {
      padding: 10px 20px;
      max-width: unset;
      margin: 0;
    }

    &__text {
      font-weight: 600;
      font-size: 28px;
      line-height: 1.5em;
      margin-bottom: 8px;
      width: 827px;
      text-align: right;

      @include respond-mobile {
        width: 256px;
        font-size: 16px;
        line-height: 1.2em;
        text-align: left;
      }
    }

    &__author {
      font-style: italic;
      font-size: 24px;
      line-height: 1.5em;

      @include respond-mobile {
        font-size: 14px;
      }
    }
  }
}

.courses {
  max-width: 1408px;
  margin: 0 auto;
  padding-top: 128px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 64px;

  @include respond-mobile {
    max-width: unset;
    margin: 0;
    padding: 32px 0 16px 20px;
    gap: 32px;
  }
}

.catalog {
  max-width: 1408px;
  width: 100%;
  margin: 0 auto;
  padding-top: 16px;
  padding-bottom: 64px;

  @include respond-mobile {
    max-width: unset;
    margin: 0;
    padding: 16px 20px 20px 20px;
  }
}

.skills {
  border-top: 1px solid #e5e5e5;

  @include respond-mobile {
    border-top: none;
  }

  &__content {
    margin: 0 auto;
    max-width: 1408px;
    padding-top: 64px;
    padding-bottom: 160px;

    @include respond-mobile {
      max-width: unset;
      margin: 0;
      padding: 16px 0 80px 20px;
    }
  }

  &__heading {
    font-weight: 700;
    font-size: 48px;
    line-height: 1.17em;

    @include respond-mobile {
      font-size: 32px;
      line-height: 1.75em;
      text-align: left;
    }
  }

  &__list_wrapper {
    margin-top: 48px;

    @include respond-mobile {
      margin-top: 32px;

      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__list {
    padding: 0 90px;
    display: flex;
    gap: 32px;
    justify-content: center;
    flex-wrap: wrap;

    @include respond-mobile {
      width: 1400px;
      justify-content: flex-start;
      padding: 0;
      gap: 24px;
    }

    &__item {
      font-size: 20px;
      line-height: 24px;
      color: #333333;
      padding: 16px 24px;
      border: 0.5px solid #bdbdbd;
      border-radius: 8px;
    }
  }
}
