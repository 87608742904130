.accordion {
  &__root {
    box-shadow: none;
    border-radius: 0;
  }
}

.accordion_summary {
  &__root {
    padding: 24px;

    > div {
      margin: 0;
    }

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5em;
      color: #333333;
    }
  }

  &__expanded {
    margin: 0 !important;
  }
}

.accordion_details {
  &__root {
    padding: 0 24px 24px 24px;
  }
}
